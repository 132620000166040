import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import HomeComponent from "./components/HomeComponent";
import AuthorizeComponent from "./components/AuthorizeComponent";
import AuthorizeCallbackComponent from "./components/AuthorizeCallbackComponent";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomeComponent />}></Route>
                <Route path="/authorize" element={<AuthorizeComponent />}></Route>
                <Route path="/callback" element={<AuthorizeCallbackComponent />}></Route>
            </Routes>
        </Router>
    );
}

export default App;
