import React, {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import Cookies from "js-cookie";

const AuthorizeComponent = () => {
    let urlAuthorize = process.env.REACT_APP_URL_AUTHORIZATION;
    let [searchParams] = useSearchParams();
    useEffect(() => {
        let customerId = searchParams.get('customerId');
        if (customerId) {
            Cookies.remove('cid');
            Cookies.set('cid', customerId, { expires: 365 * 4 });
            window.location.href = urlAuthorize;
        }
    }, [searchParams, urlAuthorize]);

    return (
        <div>Redirecting</div>
    )
}

export default AuthorizeComponent